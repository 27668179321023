
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

body {
  padding-bottom: 50px;
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.panel-heading .nav>li>a {
  padding-top: 3px;
  padding-bottom: 3px;
}

#custom-search-input {
  margin:0;
  margin-top: 10px;
  padding: 0;
}

#custom-search-input .search-query {
  width:100%;
  padding-right: 3px;
  padding-left: 15px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 0;
}

#custom-search-input button {
  border: 0;
  background: none;
  /** belows styles are working good */
  padding: 2px 5px;
  margin-top: 2px;
  position: absolute;
  right:0;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color:#D9230F;
  cursor: unset;
  z-index: 2;
}

.search-query:focus{
  z-index: 0;
}

#searchclear {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 31px;
  color: #000;
  z-index: 2147483600;
  position: fixed;
  border-top: 1px solid #666;
  visibility: visible;
  background: #fff;
  background: -moz-linear-gradient(top,#fff 0,#f8f8f8 1%,#d9d9d9 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#fff),color-stop(1%,#f8f8f8),color-stop(100%,#d9d9d9));
  background: -webkit-linear-gradient(top,#fff 0,#f8f8f8 1%,#d9d9d9 100%);
  background: -o-linear-gradient(top,#fff 0,#f8f8f8 1%,#d9d9d9 100%);
  background: -ms-linear-gradient(top,#fff 0,#f8f8f8 1%,#d9d9d9 100%);
  background: linear-gradient(to bottom,#fff 0,#f8f8f8 1%,#d9d9d9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#d9d9d9', GradientType=0);
  -webkit-box-shadow: rgba(0,0,0,.35)0 0 9px;
  box-shadow: rgba(0,0,0,.35)0 0 9px;
  font: 400 12px/31px 'Open Sans',sans-serif;
  direction: ltr;
}